import React from 'react';
import { object, shape, string } from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import parseHtml from '../utils/parseHtml';

const ImageTextBlock = ({ image, text }) => {
  return (
    <div className="flex">
      <div className="hidden lg:block lg:w-1/5">
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </div>
      <div className="lg:w-4/5 lg:pl-12" id="image-text-block">
        {parseHtml(text.html)}
      </div>
    </div>
  );
};

export default ImageTextBlock;

ImageTextBlock.propTypes = {
  text: shape({
    html: string.isRequired,
  }).isRequired,
  image: shape({
    // eslint-disable-next-line react/forbid-prop-types
    gatsbyImageData: object.isRequired,
  }).isRequired,
};
