import React from 'react';

import ImageTextBlock from '../components/ImageTextBlock';

const componentMap = {
  // "remoteTypeName": Component
  ImageTextBlock,
};

const mapComponent = (contentType, props) => {
  return React.createElement(componentMap[contentType], props);
};

export default mapComponent;
