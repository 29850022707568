import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, object, shape, string } from 'prop-types';

import { Footer, Hero, SEO } from '../components';
import mapComponent from '../utils/mapComponent';

const IndexPage = ({ data }) => {
  const {
    seo,
    path,
    heading,
    subheading,
    headerImage,
    content,
    theme,
  } = data.allGraphCmsPage.edges[0].node;

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={heading} subheading={subheading} image={headerImage} theme={theme} />
      <main className="bg-grey-light h-full pt-10 md:pt-16">
        <div className="container space-y-6 md:space-y-12">
          {content.map((block, index) => (
            <section className="bg-white p-6 md:py-12 lg:px-16" key={index}>
              {mapComponent(block.remoteTypeName, block)}
            </section>
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            heading: string.isRequired,
            subheading: string.isRequired,
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            content: arrayOf(object.isRequired).isRequired,
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const IndexPageQuery = graphql`
  query IndexPage {
    allGraphCmsPage(filter: { path: { eq: "index" } }) {
      edges {
        node {
          heading
          subheading
          path
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          content {
            ... on GraphCMS_ImageTextBlock {
              remoteTypeName
              image {
                gatsbyImageData
              }
              text {
                html
              }
            }
          }
          theme
        }
      }
    }
  }
`;
